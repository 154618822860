import React, { useState, useEffect} from "react";
import { FaTimes, FaBars } from "react-icons/fa";
import Logo from "../../../assets/img/ELECTRIC_PLUS_bleu.webp";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [navClass, setNavClass] = useState("");
  const [toggledNav, setToggledNav] = useState(false);

  const toggleNav = () => {
    setToggledNav(!toggledNav);
  };

  useEffect(() => {
    const handleScroll = () => {
      let newNavClass = "";
      if (window.scrollY >= 200) {
        newNavClass = "scrolled";
      }
      setNavClass(newNavClass);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
   

  return (
    <nav className={`navbar navbar-expand-md bg-light ${navClass}`}>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between w-100">
          <NavLink className="navbar-brand" to="/">
            <img
              style={{ width: "30%", height: "auto" }}
              src={Logo}
              alt="Logo"
            />
          </NavLink>
          <button
            className={`navbar-toggler ${toggledNav ? "collapsed" : ""}`}
            type="button"
            onClick={toggleNav}
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded={toggledNav ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            {toggledNav ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <div
          className={`collapse navbar-collapse ${toggledNav ? "show" : ""}`}
          id="navbarCollapse"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink to="/" className="nav-link">
                Accueil
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/About" className="nav-link" style={{ whiteSpace: 'nowrap' }}>
                À propos
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Contact" className="nav-link">
                Contact
              </NavLink>
            </li>
            
            <li className="nav-item">
              <NavLink to="/Bibliotheque" className="nav-link">
                Bibliotheque
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/DownloadPage" className="nav-link" style={{ whiteSpace: 'nowrap' }}>
                Ouverture de compte
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
