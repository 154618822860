import React, { Fragment } from 'react';
import Facts from "../sections/Facts";
import ScrollAnimation from 'react-animate-on-scroll';
import { motion } from 'framer-motion'; // Importez motion pour l'animation
import aboutImage from '../../assets/img/EPRLP.png';
import MD_Ouest from '../../assets/img/mondistri_avant03.jpg';
import CataloguesData from "../../data/PartenairesData";
import '../../styles/components/_about.scss';


const About = () => {

    const serviceBoxVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <Fragment>
            <main
                id="catalogues"
                style={{ backgroundColor: `#FFF` }}
            >
                <div id='about'>
                    <div className='container pt-2 pb-5'>
                        <div className='section-header pt-5 pb-5 text-center'>
                            <h3 className='section-title'>
                                <span>Electric </span>Plus
                            </h3>
                            <h6 className='section-subtitle mx-auto ml-auto'>
                                La société ELECTRIC PLUS, spécialisée dans la distribution professionnelle de matériel électrique aux Antilles depuis 1992,
                                est désormais présente à La Réunion
                            </h6>
                        </div>
                        <div className='section-content'>
                            <div className='row'>
                                {/* Première section : Image et Texte */}
                                <div className='col-md-6'>
                                    <div className='image-container'>
                                        <img className='img-fluid rounded mx-auto d-block' src={aboutImage} alt='Electric Plus Réunion' />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <h3 className='about-title'>
                                        “Entre nous le courant passe !“
                                    </h3>
                                    <div className='about-description'>
                                        <p>
                                            Plus qu’un simple slogan cette phrase évoque la relation de confiance
                                            et de proximité que nous souhaitons préserver avec nos clients. Qu’il
                                            s’agisse des produits, du service ou de l’accompagnement notre
                                            obsession est l’accessibilité.
                                        </p>
                                        <p>
                                            En somme, choisir Electric Plus, c’est vous assurer d’un lien solide
                                            et efficace avec un partenaire fiable dans le domaine de l’électricité.
                                        </p>
                                        <p>
                                            Nous avons hâte de vous accueillir dans nos nouvelles agences
                                            ELECTRIC PLUS REUNION et de poursuivre notre engagement à vous
                                            fournir le meilleur en matière de distribution de matériel électrique.
                                            Merci de votre fidélité et à très bientôt chez ELECTRIC PLUS REUNION !
                                        </p>
                                    </div>
                                </div>

                                {/* Deuxième section : Marques Partenaires et Catalogues */}
                                <div className='col-md-12'>
                                    <h3 className='about-title'>
                                        Les grandes marques partenaires
                                    </h3>
                                    <div className='about-description'>
                                        <p>
                                            Pour offrir des produits professionnels de qualité, ELECTRIC PLUS REUNION s’appuie sur de grandes marques reconnues synonymes de fiabilité et
                                            d’innovation. En collaborant avec des leaders du secteur, ELECTRIC PLUS REUNION s’assure de fournir à ses clients des solutions, adaptées à leurs
                                            besoins spécifiques. Que ce soit pour des installations électriques, ou la climatisation, l’entreprise met un point d’honneur à sélectionner des produits
                                            qui respectent des normes élevées, offrant ainsi une satisfaction optimale à sa clientèle.
                                        </p>
                                        <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300} duration={0.7}>
                                            <div className="partenaire-grid">
                                                {CataloguesData.map((catalogue) => (
                                                    <motion.div
                                                        key={catalogue.id}
                                                        variants={serviceBoxVariants}
                                                        initial='hidden'
                                                        animate='visible'
                                                        whileHover={{ scale: 1.05 }}
                                                        whileTap={{ scale: 0.95 }}
                                                        className='catalogue-item'
                                                    >
                                                        <a href={catalogue.url} target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src={catalogue.image}
                                                                className="card-img-top rounded shadow"
                                                                alt={catalogue.alt}
                                                            />
                                                        </a>
                                                    </motion.div>
                                                ))}
                                            </div>
                                        </ScrollAnimation>
                                        <p>Grâce à cette approche rigoureuse, ELECTRIC PLUS REUNION ambitionne de devenir un acteur majeur sur le marché des
                                            solutions électriques professionnelles.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* <Facts /> */}
        </Fragment>
    );
};

export default About;
