import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import imgouverturecompte from '../assets/img/outils-isole-electricien.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';

const DownloadPage = () => {
  const handleDownload = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  return (
    <main
      id="catalogues"
      style={{ backgroundColor: '#D5F2EE', minHeight: '100vh' }}
    >
      {/* Section Header */}
      <div className="container py-5">
        <div className="text-center mb-5">
          <h3
            className="section-title display-4 fw-bold"
            style={{ color: '#004996' }}
          >
            <span>Nos </span> Téléchargements
          </h3>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200} duration={2}>
            <p className="section-subtitle text-muted mx-auto" style={{ maxWidth: '600px' }}>
              Vous souhaitez ouvrir un compte chez nous ? </p>
              <p>Téléchargez directement vos documents.
            </p>
          </ScrollAnimation>
        </div>

        {/* Catalogue Grid */}
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card shadow-sm border-0">
              <img
                src={imgouverturecompte}
                alt="Electric Plus Réunion"
                className="card-img-top rounded-top"
              />
              <div className="card-body text-center">
                <h5 className="card-title fw-bold">Ouverture de compte & CGV</h5>
                <p className="text-muted small">
                  Téléchargez les documents nécessaires pour ouvrir un compte chez nous.
                </p>
                <button
                  className="btn btn-primary btn-lg w-100"
                  style={{ backgroundColor: '#004996', borderColor: '#004996' }}
                  onClick={() =>
                    handleDownload(
                      'https://documents.mondistri.re/edited_2024_10_ELECTRIC PLUS_ LA REUNION_Courrier_C800 V1.pdf'
                    )
                  }
                >
                  Télécharger
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DownloadPage;
