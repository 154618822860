// Import images
import catalogueImage1 from "../assets/img/fournisseurs/Colibri.png";
import catalogueImage2 from "../assets/img/fournisseurs/LEGARND.png";
import catalogueImage3 from "../assets/img/fournisseurs/Schneider.png";
import catalogueImage4 from "../assets/img/fournisseurs/Disano.jpg";
import catalogueImage5 from "../assets/img/fournisseurs/Resistex.jpg";
import catalogueImage6 from "../assets/img/fournisseurs/Ledvance.jpg";
import catalogueImage7 from "../assets/img/fournisseurs/Gewiss-logo.png";
import catalogueImage8 from "../assets/img/fournisseurs/Delta-Dore.png";
import catalogueImage9 from "../assets/img/fournisseurs/Dahua.png";
import catalogueImage10 from "../assets/img/fournisseurs/Ajax.png";
import catalogueImage11 from "../assets/img/fournisseurs/Planette_Wattohm.jpg";
import catalogueImage12 from "../assets/img/fournisseurs/Theben_Logo_4c.png";
import catalogueImage13 from "../assets/img/fournisseurs/Logo-agecom-gris.png";
import catalogueImage14 from "../assets/img/fournisseurs/Milwaukee.jpg";
import catalogueImage15 from "../assets/img/fournisseurs/came-logo.jpg";
import catalogueImage16 from "../assets/img/fournisseurs/Wago-Logo.png";
import catalogueImage17 from "../assets/img/fournisseurs/e-robur-logo.png";
import catalogueImage18 from "../assets/img/fournisseurs/TurboTronic.jpg";
// import catalogueImage5 from "../assets/img/fournisseurs/Dahua.png";
// import catalogueImage6 from "../assets/img/fournisseurs/SPIT.png";
// import catalogueImage7 from "../assets/img/fournisseurs/VTAC.png";
// import catalogueImage10 from "../assets/img/fournisseurs/B.E.G.png";
// import catalogueImage11 from "../assets/img/fournisseurs/BLM.png";
// import catalogueImage12 from "../assets/img/fournisseurs/Total-Tools.png";


const Url1 = '/';
const Url2 = 'https://www.legrand.fr/pro';
const Url3 = "https://www.se.com/ww/fr/";
const Url4 = "https://www.disano.it/fr/";
const Url5 = "https://www.resistex-sa.com/fr/";
const Url6 = "https://www.ledvance.fr/";
const Url7 = "https://www.gewiss.com/fr/fr/";
const Url8 = "https://www.deltadore.fr/";
const Url9 = "https://www.dahuasecurity.com/fr/";
const Url10 = "https://ajax.systems/fr/";
const Url11 = "https://www.legrand.fr/pro/catalogue/conduits-et-cheminements-de-cables/goulotte-moulure-et-plinthe-planet-wattohm";
const Url12 = "https://www.theben.fr/";
const Url13 = "https://www.agecom-diffusion.com/";
const Url14 = "https://fr.milwaukeetool.eu/";
const Url15 = "https://www.came.com/fr/";
const Url16 = "https://www.wago.com/fr/";
const Url17 = "https://www.agi-robur.com/";
const Url18 = "https://turbotronic.fr/fr";
// const Url5 = "https://www.dahuasecurity.com/fr";
// const Url6 = "https://www.spitpaslode.fr/fr/";
// const Url7 = "https://v-tac.eu/";
// const Url10 = "https://www.beg-luxomat.com/fr/";
// const Url11 = "https://www.blmd.fr/";
// const Url12 = "https://www.totalbusiness.com/";




const partenairesData = [
  {
    id: 1,
    image: catalogueImage1,
    url: Url1,
    alt: "Colibri",
  },
  {
    id: 2,
    image: catalogueImage2,
    url: Url2,
    alt: "Legrand",
  },
  {
    id: 3,
    image: catalogueImage3,
    url: Url3,
    alt: "Schneider",
  },
  {
    id: 4,
    image: catalogueImage4,
    url: Url4,
    alt: "Disano",
  },
  {
    id: 5,
    image: catalogueImage5,
    url: Url5,
    alt: "Résistex",
  },
  {
    id: 6,
    image: catalogueImage6,
    url: Url6,
    alt: "Ledvance",
  },
  {
    id: 7,
    image: catalogueImage7,
    url: Url7,
    alt: "Gewiss France",
  },
  {
    id: 8,
    image: catalogueImage8,
    url: Url8,
    alt: "DELTA Dore",
  },
  {
    id: 9,
    image: catalogueImage9,
    url: Url9,
    alt: "Dahua France",
  },
  {
    id: 10,
    image: catalogueImage10,
    url: Url10,
    alt: "Ajax Security",
  },
  {
    id: 11,
    image: catalogueImage11,
    url: Url11,
    alt: "Planet Wattohm",
  },
  {
    id: 12,
    image: catalogueImage12,
    url: Url12,
    alt: "Theben",
  },
  {
    id: 13,
    image: catalogueImage13,
    url: Url13,
    alt: "AGéCOM",
  },
  {
    id: 14,
    image: catalogueImage14,
    url: Url14,
    alt: "Milwaukee",
  },
  {
    id: 15,
    image: catalogueImage15,
    url: Url15,
    alt: "CAME",
  },
  {
    id: 16,
    image: catalogueImage16,
    url: Url16,
    alt: "Wago",
  },
  {
    id: 17,
    image: catalogueImage17,
    url: Url17,
    alt: "e-Robur",
  },
  {
    id: 18,
    image: catalogueImage18,
    url: Url18,
    alt: "Turbo Tronic",
  },
  // {
  //   id: 10,
  //   image: catalogueImage10,
  //   url: Url10,
  //   alt: "Catalogue V-TAC Solar",
  // },
  // {
  //   id: 11,
  //   image: catalogueImage11,
  //   url: Url11,
  //   alt: "Catalogue BLM",
  // },
  // {
  //   id: 12,
  //   image: catalogueImage12,
  //   url: Url12,
  //   alt: "Catalogue BEG",
  // },
  // {
  //   id: 13,
  //   image: catalogueImage13,
  //   url: Url13,
  //   alt: "Catalogue DELTA Dore",
  // },
  // {
  //   id: 14,
  //   image: catalogueImage14,
  //   url: Url14,
  //   alt: "",
  // },

];

export default partenairesData;
